<template>
    <div>
        <!--    Add new Category    -->
        <b-card :title="$t('Add Category')">
            <b-row class="">
                <b-col md="8">
                    <validation-observer ref="simpleRules">
                        <b-form-group
                            class="mb-0"
                            :label="$t('Category Name')"
                            label-for="mc-title"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="$t('Category Name')"
                            >
                                <b-form-input
                                    id="mc-title"
                                    :placeholder="$t('Category Name')"
                                    v-model="category.name"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </validation-observer>
                </b-col>
                <b-col md="1"></b-col>
                <b-col md="3" class="d-flex align-bottom">
                    <div class="w-100 d-flex align-items-end">
                        <b-button class="w-100 d-flex align-items-center justify-content-center font-medium-1"
                                  @click="addCategory" variant="success">
                            <feather-icon
                                icon="PlusSquareIcon"
                                size="18"
                                class="text-white mr-1"
                            />
                            {{ $t('Add') }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <!--    List Categories    -->
        <b-card no-body v-if="!loading">
            <b-card-body>
                <div class="d-flex justify-content-end  flex-wrap">

                    <!-- filter -->
                    <b-form-group

                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput"
                                v-model="pagination.filter"
                                type="search"
                                :placeholder="$t('Type to Search')"
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!pagination.filter"
                                    @click="pagination.filter = ''"
                                >
                                    {{ $t("Clear") }}
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </div>
            </b-card-body>

            <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="pagination.perPage"
                :current-page="pagination.currentPage"
                :items="categories"
                :fields="fields"
                :filter="pagination.filter"
            >
                <template #cell(action)="data">
                    <b-button variant="gradient-warning" @click="setEditedCategoryInfo(data.item)">
                        <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                        />
                        <span>{{ $t('Edit') }}</span>
                    </b-button>

                </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                <!-- page length -->
                <b-form-group
                    :label="$t('Per Page')"
                    label-cols="8"
                    label-align="left"
                    label-size="md"
                    label-for="perPageSelect"
                    class="text-nowrap mb-md-0 mr-1"
                >
                    <b-form-select
                        id="perPageSelect"
                        v-model="pagination.perPage"
                        size="sm"
                        inline
                        :options="pagination.pageOptions"
                    />
                </b-form-group>

                <!-- pagination -->
                <div>
                    <b-pagination
                        v-model="pagination.currentPage"
                        :total-rows="totalRows"
                        :per-page="pagination.perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </div>
            </b-card-body>
        </b-card>

        <!--    Edit Modal  -->
        <b-modal
            ref="my-modal"
            id="modal-center"
            centered
            :title="$t('Edit Category')"
            :ok-title="$t('Edit')"
            :cancel-title="$t('cancel')"
            @hidden="resetModal"
            @ok="updateCategory"
        >
            <validation-observer ref="editCategory">
                <b-form-group
                    class="mb-0"
                    :label="$t('Category Name')"
                    label-for="mc-title"
                >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="$t('Category Name')"
                    >
                        <b-form-input
                            id="mc-title"
                            :placeholder="$t('Category Name')"
                            v-model="editedCategory.name"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </b-modal>

    </div>
</template>

<script>

import {
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BCol, BDropdown, BDropdownItem,
    BFormGroup,
    BFormInput, BFormSelect,
    BInputGroup, BInputGroupAppend, BModal, BPagination,
    BRow, BTable
} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {
    required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "list",
    components: {
        BButton,
        BRow,
        BCol,
        BCard,
        BFormInput,
        BCardText,
        BDropdown,
        BDropdownItem,
        BCardBody,
        BCardTitle, BFormGroup,
        BInputGroup, BTable,
        BPagination,
        BFormSelect,
        BModal,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            loading: true,
            category: {
                name: "",
            },
            editedCategory: {
                name: "",
                id: "",
            },
            pagination: {
                perPage: 5,
                pageOptions: [3, 5, 10],
                currentPage: 1,
                filter: null,

            },
            fields: [
                {
                    key: 'id', label: 'Id',
                },
                {key: 'name', label: this.$t('Category Name'), sortable: true},
                {key: 'created_at', label: this.$t('Created At'), sortable: true},
                {key: 'action', label: this.$t('action'), sortable: false},
            ],
        }
    },
    computed: {
        categories() {
            return this.$store.state.categories.categories;
        },
        totalRows() {
            return this.$store.state.categories.categories.length;
        },
    },
    methods: {
        addCategory() {
            this.$refs.simpleRules.validate().then(result => {
                this.$store.dispatch("categories/addNewCategory", this.category).then(() => {
                    this.category = {
                        name: "",
                    };
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('success'),
                                icon: 'CheckCircleIcon',
                                text: this.$t('New Category has been added'),
                                variant: 'success',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                });
            });
        },
        setEditedCategoryInfo(cat) {
            this.editedCategory.name = cat.name;
            this.editedCategory.id = cat.id;
            this.$refs['my-modal'].show();
            console.log(cat.name);
        },
        resetModal() {
            this.editedCategory = {
                name: "",
                id: "",
            };
        },
        updateCategory() {
            this.$store.dispatch("categories/updateCategory",this.editedCategory).then(() => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Successfully'),
                            icon: 'CheckCircleIcon',
                            text: this.$t('Category has been updated'),
                            variant: 'success',
                        },
                    },
                    {
                        position: 'bottom-right',
                    })
            });
            this.resetModal();
        },
    },
    created() {
        this.$store.dispatch("categories/getCategories").then(() => {
            this.loading = false;
        });
    },
}
</script>

<style scoped>

</style>
